@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

*{
    padding: 0;
    margin: 0;
    outline: none;
    border: none;
    box-sizing: border-box;
}

img{
    width: 100%;
    display: flex;
}

a{
    text-decoration: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background-color: #18181b;
}

nav {
    max-width: 1200px;
    margin: auto;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
}

.nav-logo {
    max-width: 100px;
}

.nav-links{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 3rem;
}

.link a{
    position: relative;
    padding-bottom: 0.75rem;
    color: #fff;
}

.link a::after{
    content: "";
    position: absolute;
    height: 2px;
    width: 0;
    bottom: 0;
    left: 0;
    background-color: #1d4ed8;
    transition: all 0.3s ease;
}

.link a:hover::after{
    width: 70%;
}

.container{
    max-width: 1200px;
    margin: auto;
    padding: 5rem 2rem;
}

.blur{
    position: absolute;
    box-shadow: 0 0 1000px 50px #1d4ed8;
    z-index: -100;
}

header{
    position: relative;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
}

header .content h4{
    margin-bottom: 1rem;
    color: #ccc;
    font-size: 1rem;
    font-weight: 600;
}


header .content h1{
    color: #fff;
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: 700;
    line-height: 4rem;
}

header .content h1 span{
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px #fff;
}

header .content p{
    margin-bottom: 2rem;
    color: #ccc;
}

header .image{
    position: relative;
}

header .image img{
    max-width: 600px;
    margin: auto;
}

header .image p {
    color: #ccc;
    margin-bottom: 1rem;
    font-size: 12px;
    font-style: italic;
}

section .header{
    margin-bottom: 1rem;
    color: #fff;
    text-align: center;
    font-size: 2.25rem;
    font-weight: 600;
}

.card-style{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.card-style .card{
    background-color: #27272a;
    padding: 1rem;
    border: 2px solid transparent;
    border-radius: 15px;
    transition: all 0.3s ease;
}

.card-style .card:hover{
    background-color: #323232;
    border-color: #fff;
}

.card-style .card span{
    display: inline-block;
    background-color: #1e40af;
    padding: 2px 9px;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    color: #fff;
    border-radius: 5px;
}

.card-style .card h4{
    margin-bottom: 0.5rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
}

.card-style .card h5{
    margin-bottom: 0.5rem;
    color: #fff;
    font-style: italic;
}

.card-style .card p{
    color: #ccc;
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 3rem;
}

.card-style .card li#work{
    color: #ccc;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.card-style .card li#course{
    color: #ccc;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.card-style .card li#skills{
    color: #ccc;
    margin-left: 1rem;
}

.card-style .card a{
    color: #fff;
    transition: all 0.3s ease;
}

.card-style .card a:hover{
    color: #1e40af;
}

/*side scrolling cards*/
.card-style-horizontal{
    margin-top: 4rem;
    display: table-row;
    gap: 2rem;
    transition: left 0.5s ease;
    
}

.card-style-horizontal .card-horizontal {
    background-color: #27272a;
    padding: 1rem;
    border: 2px solid transparent;
    border-radius: 15px;
    transition: all 0.5s ease;
}

.card-style-horizontal .card-horizontal:hover{
    background-color: #323232;
    border-color: #1e40af;
}

.card-style-horizontal .card-horizontal span{
    display: inline-block;
    background-color: #1e40af;
    padding: 2px 9px;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    color: #fff;
    border-radius: 5px;
}

.card-style-horizontal .card-horizontal h4{
    margin-bottom: 0.5rem;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
}

.card-style-horizontal .card-horizontal h5{
    margin-bottom: 0.5rem;
    color: #fff;
    font-style: italic;
}

.card-style-horizontal .card-horizontal p{
    color: #ccc;
    margin-bottom: 1rem;
}

.card-style-horizontal .card-horizontal li{
    color: #ccc;
    margin-bottom: 1rem;
}

.card-style-horizontal .card-horizontal a{
    color: #fff;
    transition: all 0.3s ease;
}

.card-style-horizontal .card-horizontal a:hover{
    color: #1e40af;
}

/*Project navigation bars*/
.navigation {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  
}

.bar {
    width: 50px;
    height: 10px;
    border: 2px solid #fff;
    margin: 6px;
    cursor: pointer;
}

.bar:hover{
    background: #fff;
}

input[name="r"] {
    position: absolute;
    visibility: hidden;
}

/*Button style*/

.btn-container {
    display: flex;
    gap: 25px; /* Adjust the gap as needed */
}

.btn {
    padding: .25rem .5rem;
    font-size: .75rem;
    color: #fff;
    background-color: #1d4ed8;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn i {
    padding-right: .5rem;
}


.socials {
    text-align: center;
    color: #ccc;
    margin-bottom: 2rem;

}

.socials a {
    color: #ccc;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 1.25rem;
    border-radius: 100%;
    transition: all 0.3s ease;
    margin: 0 15px;
}

.socials a:hover{
    color: #fff;
    background-color: #1d4ed8;
    border-color: #1d4ed8;
}

@media (width < 900px) {
    .nav-links {
        display: none;
    }

    header{
        grid-template-columns: repeat(1, 1fr);
    }

    image{
        grid-area: 1/1/2/2;
    }

    .card-style {
        grid-template-columns: repeat(1, 1fr);
    }

}

